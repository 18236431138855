<template>
  <div class="h-screen flex w-full items-start justify-center" id="pa-shopify">
    <div class="pa-shopify">
      <a href="https://www.letsconnect.at/" class="register-funnel__main-block__heading__logo-wrapper">
        <img src="@/assets/images/register-funnel/LetsConnect_Logo.svg" alt="Logo" class="register-funnel__main-block__heading__logo-img" />
      </a>
      <div class="pa-shopify--body">
        <div v-if="!error" class="pa-shopify--title lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
          {{ $t(message) }}
          <indeterminate-loading :isLoading="isLoading" />
        </div>
        <div v-else class="pa-shopify--title lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
          {{ $t(error) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndeterminateLoading from '@/components/IndeterminateLoading.vue'
export default {
  components: {
    IndeterminateLoading
  },
  data: () => ({
    query: null,
    shopifyState: null,
    error: null,
    isLoading: true,
    message: 'shopify.setupAccount',
    shopifyMessageKey: Math.random().toString(36).substring(2, 15)
  }),

  async mounted() {
    this.shopifyState = localStorage.getItem('pa-shopify-state')
    localStorage.removeItem('pa-shopify-state')
    this.query = this.$route.query

    if (this.query && this.shopifyState) {
      try {
        this.shopifyRef = this.$firebase.database().ref(`shopify/${this.shopifyMessageKey}`)
        this.shopifyRef.on('value', (snapshot) => {
          const val = snapshot.val()
          if (val && val.message) {
            this.message = val.message
          }
        })
        await this.shopifyAccount()
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error)
      }
    } else {
      this.message = 'Invalid state'
    }
  },

  methods: {
    async shopifyAccount() {
      const authorizeShopify = this.$functions.httpsCallable('authorizeShopify')
      const result = await authorizeShopify({ state: this.shopifyState, query: this.query, shopifyMessageKey: this.shopifyMessageKey })
      if (result.data.error) {
        this.error = result.data.message
      }

      if (result.data && result.data.userId) {
        this.message = 'shopify.redirect'
        await this.signInWithCustomToken(result.data.userId)
        const firebaseCurrentUser = this.$firebase.auth().currentUser
        if (firebaseCurrentUser) {
          if (result.data.dialogId) {
            this.$router.push({ name: 'dialog-item', params: { id: result.data.dialogId } })
          } else {
            this.$router.push({ name: 'incoming-requests' })
          }
        }
      }
    },
    async signInWithCustomToken(uid) {
      await this.$firebase.auth().signOut()
      this.$firebase.database().ref(`shopify/${this.shopifyMessageKey}`).set(null)
      const getUserCustomToken = this.$functions.httpsCallable('getUserCustomToken')
      const customToken = await getUserCustomToken({ uid })
      if (customToken && customToken.data && customToken.data.status) {
        const token = customToken.data.token
        await this.$firebase.auth().signInWithCustomToken(token)
        await this.$firebase.auth().currentUser.getIdTokenResult(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.pa-shopify {
  padding: 20px;
  width: calc(70vw);
  &--body {
    background-color: rgba(225, 225, 225, 0.2);
    border-radius: 6px;
  }
  &--title {
    color: #262629;
    font-family: 'Larsseit-Bold';
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.177778px;
  }
}
@font-face {
  font-family: 'Larsseit-Regular';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Regular.woff');
}

@font-face {
  font-family: 'Larsseit-Medium';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Medium.woff');
}

@font-face {
  font-family: 'Larsseit-Bold';
  src: url('../../../assets/fonts/Larsseit/Larsseit-Bold.woff');
}
</style>
